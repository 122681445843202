<template>
  <div class="px-5">
    <div class="hidden-sm-and-down">
      <v-row>
        <v-col cols="10">
          <div class="text-h4 primary--text">
            รายงาน ค่าคอม พ.ร.บ. ประกันภัย
          </div>
        </v-col>

        <v-col cols="2" class="text-right">
          <v-btn
            color="success"
            small
            outlined
            class="ml-5 px-5"
            @click="download_excel()"
            >ดาวน์โหลด <v-icon right small>mdi-download</v-icon></v-btn
          >
        </v-col>
      </v-row>
      <v-toolbar
        height="150px"
        dense
        flat
        rounded
        outlined
        class="my-5 pt-3 pb-15"
      >
        <v-row>
          <v-col cols="2">
            <v-select
              label="เลือกบริษัท"
              v-model="sub_category"
              :items="sub_category_list"
              item-value="_id"
              item-text="name"
              return-object
              outlined
              rounded
              dense
              hide-details
              @change="(val) => onChangeSubCategory(val)"
            >
              <template slot="item" slot-scope="data">
                {{ data.item.name }} {{ data.item.broker }}
              </template>
              <template slot="selection" slot-scope="data">
                {{ data.item.name }} {{ data.item.broker }}
              </template>
            </v-select>
          </v-col>
          <v-col cols="2">
            <v-select
              label="จากวันที่"
              v-model="filter.date_type"
              :items="selected_dates"
              item-value="value"
              item-text="text"
              outlined
              rounded
              dense
              hide-details
            >
            </v-select>
          </v-col>
          <v-col cols="2">
            <v-select
              label="ช่วงเวลา"
              v-model="timeRangeType"
              :items="timeRangeTypes"
              item-value="value"
              item-text="text"
              outlined
              rounded
              dense
              hide-details
            >
            </v-select>
          </v-col>
          <v-col cols="2">
            <DatePicker
              v-if="timeRangeType == 1"
              label="วันจดทะเบียน"
              :value="filter.start_date"
              @onChange="(val) => (filter.start_date = val)"
              hideDetails
              outlined
              rounded
              dense
            />
          </v-col>
          <v-col cols="2">
            <DatePicker
              v-if="timeRangeType == 1"
              label="ถึงวันที่"
              :value="filter.end_date"
              @onChange="(val) => (filter.end_date = val)"
              hideDetails
              outlined
              rounded
              dense
            />
          </v-col>
          <v-col cols="2">
            <v-btn color="primary" small class="float-right" @click="getData()"
              >แสดงรายงาน <v-icon right>mdi-magnify</v-icon></v-btn
            >
          </v-col>

          <v-col cols="1">
            <v-text-field
              label="ภาษี 1"
              outlined
              dense
              suffix="%"
              v-model="filter.tax1"
              :items="filter.tax1"
              @keyup="getData"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              maxlength="2"
            ></v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field
              label="ภาษี 2"
              outlined
              dense
              suffix="%"
              v-model.number="filter.tax2"
              :items="filter.tax2"
              @keyup="getData"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              maxlength="2"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn color="error" small @click="setVat()"
              >ปรับภาษีให้เป็น 0 %</v-btn
            >
          </v-col>
          <v-col class="error--text py-2" cols="3"
            ><span
              >ค่าภาษีเป็นค่ามาตรฐานที่บางบริษัทจะหักออกไปจากค่าคอมที่เราได้
              เช่น อาคเนย์ เป็นต้น</span
            ></v-col
          >
          <v-col cols="3">
            <div class="float-right pr-1">
              <span
                >ยอดนำส่งรวม:
                {{ this.delivery_amounts.toFixed(2) | numeral('0,0.00') }}
                บาท</span
              >
            </div>
          </v-col>
          <v-col cols="2">
            <div class="float-right pr-1">
              <span
                >กำไรรวมที่ได้:
                {{ this.profit_amounts.toFixed(2) | numeral('0,0.00') }}
                บาท</span
              >
            </div>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-data-table
        class="elevation-2"
        :headers="headers"
        :items="commission_list"
        :loading="loading"
        sort-by=""
        :loading-text="$table_loading_text"
        :page.sync="page"
        :items-per-page="item_per_page"
        hide-default-footer
        item-key="_id"
      >
        <template v-slot:[`header.date`]>
          <span v-if="filter.date_type == 1"> {{ `วันที่สร้างบิล` }}</span>
          <span v-if="filter.date_type == 2"> {{ `วันที่คุ้มครอง` }}</span>
        </template>
        <template v-slot:[`item.index`]="{ item }">
          {{
            commission_list
              .map(function (x) {
                return x;
              })
              .indexOf(item) + 1
          }}
        </template>

        <template v-slot:[`item.date`]="{ item }">
          {{ formatDate(item.date) }}
        </template>
        <template v-slot:[`item.product_type`]="{ item }">
          <span v-if="item.product_type === 'third_insurance'"> พ.ร.บ</span>
          <span v-if="item.product_type === 'insurance'"> ประกันภัย</span>
        </template>

        <template v-slot:[`item.commision1`]="{ item }">
          {{ item.commision1 + '%' }}
        </template>

        <template v-slot:[`item.commision2`]="{ item }">
          {{ item.commision2 + '%' }}
        </template>
        <template v-slot:[`header.com1`]>
          <span class="error--text"> {{ 'คอม1' }}</span>
        </template>
        <template v-slot:[`header.com2`]>
          <span class="error--text"> {{ 'คอม2' }}</span>
        </template>
        <template v-slot:[`item.com1`]="{ item }">
          <span class="error--text"> {{ item.com1 }}</span>
        </template>
        <template v-slot:[`item.com2`]="{ item }">
          <span class="error--text"> {{ item.com2 }}</span>
        </template>
        <template v-slot:footer>
          <table-pagination-custom
            :page="page"
            :itemsPerPage="item_per_page"
            :length="pageCount"
            @change-page="onChangePage"
            @change-items-per-page="onChangeitemsPerPage"
          />
        </template>
      </v-data-table>
    </div>
    <div class="hidden-md-and-up">
      <v-row cols="12">
        <div class="text-h6 primary--text">รายงาน ค่าคอม พ.ร.บ. ประกันภัย</div>
      </v-row>
      <v-row justify="end" class="mb-3">
        <v-btn
          color="success"
          small
          outlined
          class="ml-5 px-10 float-right"
          @click="download_excel()"
          >ดาวน์โหลด <v-icon right small>mdi-download</v-icon></v-btn
        >
      </v-row>
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="6" class="px-1">
              <div style="min-width: 100px">
                <v-select
                  label="เลือกบริษัท"
                  v-model="sub_category"
                  :items="sub_category_list"
                  item-value="_id"
                  item-text="name"
                  return-object
                  outlined
                  rounded
                  dense
                  hide-details
                  @change="(val) => onChangeSubCategory(val)"
                >
                  <template slot="item" slot-scope="data">
                    {{ data.item.name }} {{ data.item.broker }}
                  </template>
                  <!-- <template slot="selection" slot-scope="data">
                    {{ data.item.name }} {{ data.item.broker }}
                  </template> -->
                </v-select>
              </div>
            </v-col>
            <v-col cols="6" class="px-1">
              <v-select
                label="จากวันที่"
                v-model="filter.date_type"
                :items="selected_dates"
                item-value="value"
                item-text="text"
                outlined
                rounded
                dense
                hide-details
              >
              </v-select>
            </v-col>
            <v-col cols="6" class="px-1">
              <v-select
                label="ช่วงเวลา"
                v-model="timeRangeType"
                :items="timeRangeTypes"
                item-value="value"
                item-text="text"
                outlined
                rounded
                dense
                hide-details
              >
              </v-select>
            </v-col>
            <v-col cols="6" v-if="timeRangeType == 1" class="px-0">
              <DatePicker
                label="วันจดทะเบียน"
                :value="filter.start_date"
                @onChange="(val) => (filter.start_date = val)"
                hideDetails
                outlined
                rounded
                dense
              />
            </v-col>
            <v-col cols="6" v-if="timeRangeType == 1" class="px-0">
              <DatePicker
                label="ถึงวันที่"
                :value="filter.end_date"
                @onChange="(val) => (filter.end_date = val)"
                hideDetails
                outlined
                rounded
                dense
              />
            </v-col>
            <v-col cols="6" class="px-1">
              <v-btn
                color="primary"
                small
                class="float-right"
                @click="getData()"
                >แสดงรายงาน <v-icon right>mdi-magnify</v-icon></v-btn
              >
            </v-col>
            <v-col cols="3" class="px-1">
              <v-text-field
                label="ภาษี 1"
                outlined
                dense
                suffix="%"
                v-model="filter.tax1"
                :items="filter.tax1"
                @keyup="getData"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                maxlength="2"
              ></v-text-field>
            </v-col>
            <v-col cols="3" class="px-1">
              <v-text-field
                label="ภาษี 2"
                outlined
                dense
                suffix="%"
                v-model.number="filter.tax2"
                :items="filter.tax2"
                @keyup="getData"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                maxlength="2"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="px-1">
              <v-btn
                color="error"
                class="mt-1 float-right"
                small
                @click="setVat()"
                >ปรับภาษีให้เป็น 0 %</v-btn
              >
            </v-col>
            <!-- <v-col class="error--text" cols="3"
              ><span
                >ค่าภาษีเป็นค่ามาตรฐานที่บางบริษัทจะหักออกไปจากค่าคอมที่เราได้
                เช่น อาคเนย์ เป็นต้น</span
              ></v-col
            > -->
            <v-row class="mb-1 text-right">
              <v-col>
                <span
                  >ยอดนำส่งรวม:
                  {{ this.delivery_amounts.toFixed(2) | numeral('0,0.00') }}
                  บาท</span
                ></v-col
              >
              <v-col class="mr-1">
                <span
                  >กำไรรวมที่ได้:
                  {{ this.profit_amounts.toFixed(2) | numeral('0,0.00') }}
                  บาท</span
                >
              </v-col>
            </v-row>
          </v-row>
        </v-card-text></v-card
      >
      <v-data-table
        class="elevation-2"
        :headers="headers"
        :items="commission_list"
        :loading="loading"
        sort-by=""
        :loading-text="$table_loading_text"
        :page.sync="page"
        :items-per-page="item_per_page"
        hide-default-footer
        item-key="_id"
      >
        <template v-slot:[`header.date`]>
          <span v-if="filter.date_type == 1"> {{ `วันที่สร้างบิล` }}</span>
          <span v-if="filter.date_type == 2"> {{ `วันที่คุ้มครอง` }}</span>
        </template>
        <template v-slot:[`item.index`]="{ item }">
          {{
            commission_list
              .map(function (x) {
                return x;
              })
              .indexOf(item) + 1
          }}
        </template>

        <template v-slot:[`item.date`]="{ item }">
          {{ formatDate(item.date) }}
        </template>
        <template v-slot:[`item.product_type`]="{ item }">
          <span v-if="item.product_type === 'third_insurance'"> พ.ร.บ</span>
          <span v-if="item.product_type === 'insurance'"> ประกันภัย</span>
        </template>

        <template v-slot:[`item.commision1`]="{ item }">
          {{ item.commision1 + '%' }}
        </template>

        <template v-slot:[`item.commision2`]="{ item }">
          {{ item.commision2 + '%' }}
        </template>
        <template v-slot:[`header.com1`]>
          <span class="error--text"> {{ 'คอม1' }}</span>
        </template>
        <template v-slot:[`header.com2`]>
          <span class="error--text"> {{ 'คอม2' }}</span>
        </template>
        <template v-slot:[`item.com1`]="{ item }">
          <span class="error--text"> {{ item.com1 }}</span>
        </template>
        <template v-slot:[`item.com2`]="{ item }">
          <span class="error--text"> {{ item.com2 }}</span>
        </template>
        <template v-slot:footer>
          <table-pagination-custom
            :page="page"
            :itemsPerPage="item_per_page"
            :length="pageCount"
            @change-page="onChangePage"
            @change-items-per-page="onChangeitemsPerPage"
          />
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import DatePicker from '@/components/DatePicker.vue';
import * as moment from 'moment';

export default {
  components: {
    DatePicker,
  },
  data: () => ({
    loading: true,
    pageCount: 0,
    branch_id: '',
    timeRangeTypes: [
      { value: 0, text: 'วันนี้' },
      { value: 1, text: 'เลือกช่วงวันที่' },
    ],
    selected_dates: [
      { value: 1, text: 'วันที่สร้างบิล' },
      { value: 2, text: 'วันที่คุ้มครอง' },
    ],
    commission_list: [],
    timeRangeType: 0,
    selected_date: 0,
    page: 1,
    item_per_page: 110,
    sub_category: null,
    sub_category_list: [],
    delivery_amounts: 0,
    profit_amounts: 0,
    filter: {
      commission_array: [],
      date_type: 1,
      branch_id: '',
      sub_category_id: '',
      tax1: 12,
      tax2: 3,
      start_date: moment().add(-0, 'days').format('YYYY-MM-DD'),

      end_date: moment().add(0, 'days').format('YYYY-MM-DD'),
      delivery_amount: 0,
      profit_amount: 0,
    },
    headers: [
      { text: '#', value: 'index', sortable: false },
      { text: 'วันที่สร้างบิล', value: 'date' },
      { text: 'ชื่อ', value: 'customer_name', sortable: false },
      { text: 'เลขทะเบียน', value: 'car_plate' },
      { text: 'ประเภท', value: 'product' },
      { text: 'เบี้ยสุทธิ', value: 'insurance_premium_net', sortable: false },
      { text: 'อากร', value: 'stamp', sortable: false },
      { text: 'ภาษี', value: 'vat', sortable: false },
      { text: 'เบี้ยรวม', value: 'insurance_premium', sortable: false },
      { text: '%คอม 1', value: 'commision1', sortable: false },
      { text: 'คอม 1', value: 'com1', sortable: false },
      { text: 'ภาษี 1', value: 'vat1', sortable: false },
      { text: '%คอม 2', value: 'commision2', sortable: false },
      { text: 'คอม 2', value: 'com2', sortable: false },
      { text: 'ภาษี 2', value: 'vat2', sortable: false },
      { text: 'ยอดนำส่ง', value: 'delivery_amount', sortable: false },
      { text: 'กำไรที่ได้', value: 'profit_amount', sortable: false },
    ],
  }),
  async created() {
    this.filter.branch_id = this.$store.state.selected_branch._id;
    await this.getSubCategoryList();
    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;
      console.log('getData()');
      console.log(this.filter);
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/insurance_commission/report_commission`, body)
        .then((res) => {
          this.commission_list = res.result.commission_array;
          if (this.commission_list.length % this.item_per_page == 0) {
            this.pageCount =
              parseInt(this.commission_list.length / this.item_per_page) || 1;
          } else {
            this.pageCount =
              parseInt(this.commission_list.length / this.item_per_page) + 1 ||
              1;
          }
          if (this.sub_category.tag != 'RVP') {
            console.log('not RVP');
            for (let i = 0; i < this.commission_list.length; i++) {
              this.commission_list[i].insurance_premium_net =
                this.commission_list[i].insurance_premium / 1.07;
              this.commission_list[i].stamp = Math.ceil(
                this.commission_list[i].insurance_premium_net -
                  this.commission_list[i].insurance_premium_net / 1.004
              );
              this.commission_list[i].vat = (
                this.commission_list[i].insurance_premium_net * 0.07
              ).toFixed(2);
              this.commission_list[i].insurance_premium_net = (
                this.commission_list[i].insurance_premium_net -
                this.commission_list[i].stamp
              ).toFixed(2);
              this.commission_list[i].com1 = (
                this.commission_list[i].insurance_premium_net *
                (this.commission_list[i].commision1 / 100)
              ).toFixed(2);
              this.commission_list[i].vat1 = (
                this.commission_list[i].com1 *
                (this.filter.tax1 / 100)
              ).toFixed(2);
              this.commission_list[i].com2 = (
                this.commission_list[i].insurance_premium_net *
                (this.commission_list[i].commision2 / 100)
              ).toFixed(2);
              this.commission_list[i].vat2 = (
                this.commission_list[i].com2 *
                (this.filter.tax2 / 100)
              ).toFixed(2);
              this.commission_list[i].profit_amount = (
                parseFloat(this.commission_list[i].com1) +
                parseFloat(this.commission_list[i].com2) -
                (parseFloat(this.commission_list[i].vat1) +
                  parseFloat(this.commission_list[i].vat2))
              ).toFixed(2);
              this.commission_list[i].delivery_amount = (
                this.commission_list[i].insurance_premium -
                this.commission_list[i].profit_amount
              ).toFixed(2);
              // console.log(this.list[i]);
            }
          } else {
            console.log('RVP');
            for (let i = 0; i < this.commission_list.length; i++) {
              this.commission_list[i].insurance_premium_net =
                this.commission_list[i].insurance_premium / 1.07;
              this.commission_list[i].stamp = Math.ceil(
                this.commission_list[i].insurance_premium_net -
                  this.commission_list[i].insurance_premium_net / 1.004
              );
              this.commission_list[i].vat = (
                this.commission_list[i].insurance_premium_net * 0.07
              ).toFixed(2);
              this.commission_list[i].insurance_premium_net = (
                this.commission_list[i].insurance_premium_net -
                this.commission_list[i].stamp
              ).toFixed(2);

              let diff_days = moment
                .duration(
                  moment(this.commission_list[i].end_date).diff(
                    moment(this.commission_list[i].start_date)
                  )
                )
                .asDays();
              if (diff_days >= 0 && diff_days <= 179) {
                this.commission_list[i].com1 = (0).toFixed(2);
              }
              if (diff_days > 179 && diff_days <= 364) {
                this.commission_list[i].com1 = (5).toFixed(2);
              }
              if (diff_days > 364 && diff_days <= 454) {
                this.commission_list[i].com1 = (10).toFixed(2);
              }
              if (diff_days > 454 && diff_days <= 634) {
                this.commission_list[i].com1 = (15).toFixed(2);
              }
              if (diff_days > 634 && diff_days <= 730) {
                this.commission_list[i].com1 = (20).toFixed(2);
              }
              this.commission_list[i].vat1 = (
                this.commission_list[i].com1 *
                (this.filter.tax1 / 100)
              ).toFixed(2);
              this.commission_list[i].com2 = (0).toFixed(2);
              this.commission_list[i].vat2 = (
                this.commission_list[i].com2 *
                (this.filter.tax2 / 100)
              ).toFixed(2);
              this.commission_list[i].profit_amount = (
                parseFloat(this.commission_list[i].com1) +
                parseFloat(this.commission_list[i].com2) -
                (parseFloat(this.commission_list[i].vat1) +
                  parseFloat(this.commission_list[i].vat2))
              ).toFixed(2);
              this.commission_list[i].delivery_amount = (
                this.commission_list[i].insurance_premium -
                this.commission_list[i].profit_amount
              ).toFixed(2);
            }
          }
          this.filter.commission_array = this.commission_list;
          this.sum_delivery_amounts();
          this.sum_profit_amounts();
        })
        .catch((err) => {
          console.log('err', err);
          // this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    setVat() {
      this.filter.tax1 = 0;
      this.filter.tax2 = 0;
      this.getData();
    },
    onChangeSubCategory(val) {
      this.filter.sub_category_id = val._id;
      if (this.sub_category.tag == 'RVP') {
        this.filter.tax1 = 0;
        this.filter.tax2 = 0;
      } else {
        this.filter.tax1 = 12;
        this.filter.tax2 = 3;
      }
    },
    onChangePage(page) {
      this.page = page;
      this.getData();
    },
    onChangeitemsPerPage(number) {
      this.item_per_page = number;
      this.onChangePage(1);
    },
    async getSubCategoryList() {
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios
        .post(
          `${this.$baseUrl}/sub_category/get_insurance_sub_category_list`,
          body
        )
        .then(async (res) => {
          for (var i = 0; i < res.result.length; i++) {
            this.sub_category_list.push(res.result[i]);
          }
          this.sub_category = this.sub_category_list[0];
          this.filter.sub_category_id = this.sub_category_list[0]._id;
        });
    },
    formatDate(date) {
      if (!date) {
        return '';
      }
      let year = parseInt(moment(date).format('YYYY'));
      let m_d = moment(date).format('DD/MM');
      if (m_d === 'Invalid date') return '';
      return `${m_d}/${year + 543}`;
    },
    sum_profit_amounts() {
      this.profit_amounts = 0;
      for (let i = 0; i < this.commission_list.length; i++) {
        this.profit_amounts += parseFloat(
          this.commission_list[i].profit_amount
        );
      }
      return this.profit_amounts;
    },
    sum_delivery_amounts() {
      this.delivery_amounts = 0;
      for (let i = 0; i < this.commission_list.length; i++) {
        this.delivery_amounts += parseFloat(
          this.commission_list[i].delivery_amount
        );
      }
      return this.delivery_amounts;
    },
    async download_excel() {
      this.loading = true;
      this.filter.delivery_amount = this.delivery_amounts.toFixed(2);
      this.filter.profit_amount = this.profit_amounts.toFixed(2);
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios({
        method: 'post',
        url: `${this.$baseUrl}/insurance_commission/download_report_commission`,
        responseType: 'blob',
        data: body,
      })
        .then((response) => {
          if (
            moment(this.filter.start_date).format('DDMMYYYY') !=
            moment(this.filter.end_date).format('DDMMYYYY')
          ) {
            this.forceFileDownload(
              response,
              'ค่าคอมมิชชั่น พ.ร.บ ประกันภัย' +
                moment(this.filter.start_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '_' +
                moment(this.filter.end_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '.xlsx'
            );
          } else {
            this.forceFileDownload(
              response,
              'ค่าคอมมิชชั่น พ.ร.บ ประกันภัย' +
                moment(this.filter.start_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '.xlsx'
            );
          }
        })
        .catch((err) => {
          console.log('err', err);
          // this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', title);
      document.body.appendChild(link);
      link.click();
    },
  },
  watch: {
    timeRangeType: function (value, oldValue) {
      if (value == 0) {
        // 0 = วันนี้
        this.filter.start_date = moment().format('YYYY-MM-DD');
        this.filter.end_date = this.filter.start_date;
      } else if (value == 1) {
        // 1 = เลือกช่วงวันที่
        this.filter.start_date = moment().startOf('month').format('YYYY-MM-DD');
        this.filter.end_date = moment().endOf('month').format('YYYY-MM-DD');
      }
    },
  },
};
</script>
